import { queryClient } from '@/plugins/vue-query';
import { BaseSocket } from './base.socket';
import type { UserNotification } from '@/interfaces/userNotification.interface';

/**
 * Handles WebSocket connections for ecommerce-related real-time communications.
 */
export class NotificationSocket extends BaseSocket {
  private static readonly CHANNEL_PREFIX = 'private-cxm.notifications';

  /**
   * Creates an instance of NotificationSocket.
   * @param {number} user_id - The ID of the ecommerce to subscribe to.
   */
  constructor(user_id: number) {
    const channelName = `${NotificationSocket.CHANNEL_PREFIX}.${user_id}`;
    super(channelName);
  }

  /**
   * Sets up all event listeners for this socket
   */
  protected setupEventListeners(): void {
    this.onEvent(() => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
    });
  }

  /**
   * Binds a callback to handle all notification events.
   * @param {Function} callback - The callback to execute when the notification is received.
   */
  onEvent(callback: (data: UserNotification) => void): void {
    this.bindAll(callback);
  }
}
