import type { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '',
    component: () => import('@/modules/admin/layouts/BaseLayout.vue'),
    children: [
      {
        path: 'select-account',
        name: 'AdminSelectAccountView',
        component: () => import('@/modules/admin/views/AdminSelectAccountView.vue'),
      },
      {
        path: 'account/:account_id/order/:order_id/redirect',
        name: 'AdminAccountOrderRedirectView',
        component: () => import('@/modules/admin/views/AdminAccountOrderRedirectView.vue'),
        props: (to) => ({
          account_id: Number(to.params.account_id),
          order_id: Number(to.params.order_id),
        }),
      },
    ],
  },
] satisfies RouteRecordRaw[];
