import { EcommerceSettingsPlanType } from '@/interfaces/ecommerce.interface';
import { PlanSubscriptionApprovalStatus } from '@/interfaces/planSubscriptionApproval.interface';
import { i18n } from '@/plugins/i18n';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { useUiStore } from '@/stores/ui.store';
import { toast } from '@/utils/toast.utils';
import type { NavigationGuard } from 'vue-router';

export const ecommerceMiddlewares: Record<string, NavigationGuard> = {
  isPaid: async (to, from, next) => {
    const ecommerceStore = useEcommerceStore();
    const { t } = i18n.global as any;
    if (
      ecommerceStore.currentEcommerce?.settings.flokee_plan_type === EcommerceSettingsPlanType.PAID
    ) {
      return next();
    }

    toast.error({ message: t('notifications.must_purchase_active_plan') });
    next({ path: from.path });
  },
  showPlanSubscriptionApprovalModal: async (to, from, next) => {
    const ecommerceStore = useEcommerceStore();
    await ecommerceStore.getPlanSubscriptionApprovals();

    if (
      ecommerceStore.plan_subscription_approvals.find(
        (psa) => psa.status === PlanSubscriptionApprovalStatus.APPROVED,
      ) ||
      ecommerceStore.currentEcommerce?.settings.flokee_plan_type === EcommerceSettingsPlanType.PAID
    )
      return next();

    const uiStore = useUiStore();
    uiStore.openPlanSubscriptionModal();
    if (!from.name) return next({ name: 'DashboardView' });
    return false;
  },
};
