import { authMiddlewares } from '@/middlewares/auth.middlewares';
import { i18n } from '@/plugins/i18n';
import type { RouteRecordRaw } from 'vue-router';
const { t } = i18n.global as any;

export default [
  {
    path: '',
    component: () => import('@/modules/geo/layouts/DashboardLayout.vue'),
    beforeEnter: [
      authMiddlewares.isAuthenticatedGeo,
      authMiddlewares.isAccountConfigured,
      authMiddlewares.isGeoAccount,
    ],
    children: [
      {
        path: '',
        name: 'GeoDashboardView',
        meta: { title: t('nav_menu.dashboard') },
        component: () => import('@/modules/geo/views/common/DashboardView.vue'),
      },
      {
        path: 'interactions',
        name: 'GeoInteractionsView',
        meta: { title: t('nav_menu.interactions') },
        component: () => import('@/modules/geo/views/common/InteractionsView.vue'),
      },
      {
        path: 'contacts',
        name: 'GeoContactsView',
        meta: { title: t('nav_menu.contacts') },
        component: () => import('@/modules/geo/views/common/ContactsView.vue'),
      },
    ],
  },
  {
    path: 'auth',
    component: () => import('@/layouts/BlankLayout.vue'),
    redirect: 'auth/login',
    children: [
      {
        path: 'login',
        name: 'GeoAuthLoginView',
        meta: { title: t('auth.sign_in.title') },
        component: () => import('@/modules/geo/views/auth/AuthLoginView.vue'),
      },
      {
        path: 'email',
        name: 'GeoAuthEmailView',
        meta: { title: t('auth.sign_in_email.title') },
        props: (route) => ({
          email: route.query.email ?? '',
        }),
        component: () => import('@/modules/geo/views/auth/AuthEmailView.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
