<!-- ProgrammaticModal.vue -->
<script setup lang="ts">
import ModalDialog from './ModalDialog.vue'; // Adjust the import path as necessary
import OutlineButton from './OutlineButton.vue';
import PrimaryButton from './PrimaryButton.vue';

const { isOpen, modalProps, handleCancel, handleConfirm } = useConfirmModal();

const icon = computed(() => {
  if (modalProps.icon) return modalProps.icon;
  switch (modalProps.type) {
    case 'success':
      return 'heroicons:check-circle';
    case 'error':
      return 'heroicons:x-circle';
    case 'info':
      return 'heroicons:information-circle';
    case 'warning':
      return 'heroicons:exclamation-triangle';
    default:
      return 'heroicons:information-circle';
  }
});
</script>

<template>
  <ModalDialog
    :static="modalProps.static"
    @close="handleCancel"
    v-model="isOpen"
    :title="modalProps.title"
    contentClass="text-center"
  >
    <template #icon>
      <div>
        <IconifyIcon
          :icon="icon"
          class="size-10"
          :class="{
            'text-system-success-green': modalProps.type === 'success',
            'text-system-error-red': modalProps.type === 'error',
            'text-brand-primary': modalProps.type === 'info',
            'text-system-warning-yellow': modalProps.type === 'warning',
          }"
        />
      </div>
    </template>
    <template #default>
      <p class="text-semantic-text-secondary">
        {{ modalProps.description }}
      </p>
    </template>

    <template #footer>
      <div class="flex justify-center gap-4">
        <template v-if="!modalProps.invertButtons">
          <OutlineButton @click="handleCancel">{{ modalProps.cancelButtonText }}</OutlineButton>
          <PrimaryButton @click="handleConfirm">{{ modalProps.confirmButtonText }}</PrimaryButton>
        </template>
        <template v-else>
          <OutlineButton @click="handleConfirm">{{ modalProps.confirmButtonText }}</OutlineButton>
          <PrimaryButton @click="handleCancel">{{ modalProps.cancelButtonText }}</PrimaryButton>
        </template>
      </div>
    </template>
  </ModalDialog>
</template>
