import type { User } from '@/interfaces/user.interface';
import { cxmService } from '@/services/cxm.service';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import type { MaybeRef } from 'vue';
import { useEcommerceStore } from './ecommerce.store';
import { i18n, setLocaleByLanguageCode } from '@/plugins/i18n';
import { LoginProvider } from '@/interfaces/loginProvider.interface';
import type {
  CreateAccountDTO,
  CreateAccountOrderDTO,
  UpdateAccountDTO,
  UpdateUserDTO,
} from '@/services/cxm.service.interface';
import { getIntercom } from '@/plugins/intercom';
import { queryClient } from '@/plugins/vue-query';
import * as VueGtag from 'vue-gtag';
import { isProduction } from '@/utils/environment.utils';
import { useLandingStore } from '@/modules/geo/stores/landing.store';
import { OrderStatus } from '@/interfaces/order.interface';
import { notice } from '@/utils/notice.utils';
import { useUiStore } from './ui.store';
import { NotificationSocket } from '@/sockets/notification.socket';

interface AuthState {
  access_token: MaybeRef<string | undefined>;
  login_provider: MaybeRef<LoginProvider | undefined>;
  user?: User;
  notificationSocket?: NotificationSocket;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    access_token: useStorage('access_token', undefined, localStorage),
    login_provider: useStorage('login_provider', undefined, localStorage),
    user: undefined,
    notificationSocket: undefined,
  }),
  getters: {
    currentAccount: (state) => {
      return state.user?.current_account;
    },
    isGeoAccount: (state) => {
      return !!state.user?.current_account?.partner.tags?.includes('geo');
    },
    isFlokeeAccount: (state) => {
      return !!state.user?.current_account?.partner.tags?.includes('flokee');
    },
  },
  actions: {
    async init() {
      this.subscribeToNotificationSocket();
      const ecommerceStore = useEcommerceStore();
      const landingStore = useLandingStore();
      if (this.isFlokeeAccount) await ecommerceStore.init();
      if (this.isGeoAccount) await landingStore.init();
      const { t } = i18n.global as any;

      if (isProduction()) {
        //Initialize intercom instance
        const intercom = getIntercom();
        if (intercom && this.user) {
          intercom.boot({
            user_id: this.user.id,
            name: this.user.name,
            email: this.user.email,
            created_at: this.user.created_at,
          });
        }
        //Set gtag user id
        if (this.isFlokeeAccount)
          VueGtag.set({ user_id: `${this.user?.id}-${ecommerceStore.currentEcommerce?.id}` });
        if (this.isGeoAccount)
          VueGtag.set({ user_id: `${this.user?.id}-${landingStore.currentLanding?.id}` });
      }

      //check if account is suspended and show notice
      if (this.user?.current_order?.status === OrderStatus.SUSPENDED) {
        notice.error(t('notices.suspended_account'), {
          id: 'suspended-account',
          dismissable: false,
          actions: [
            {
              text: t('common.contact_support'),
              onClick: async () => {
                const uiStore = useUiStore();
                uiStore.openSupportDrawer();
              },
            },
          ],
        });
      } else {
        notice.remove('suspended-account');
      }
    },
    async register(register_data: {
      name: string;
      email: string;
      password: string;
      timezone: string;
      language_id: number;
    }) {
      await cxmService.register(register_data);

      await this.login(register_data.email, register_data.password);
    },
    async login(email: string, password: string) {
      const { data: response } = await cxmService.login(email, password);

      const { data } = response;

      this.access_token = data.access_token;
      this.login_provider = LoginProvider.Cxm;

      await this.invalidate();
      await this.getCurrentUser();
    },
    async loginWithTiendanube(code: string) {
      const { data: response } = await cxmService.tiendanubeLogin(code);

      const { data } = response;

      this.access_token = data.access_token;
      this.login_provider = LoginProvider.Tiendanube;

      await this.invalidate();
      await this.getCurrentUser();
    },
    async loginWithGoogle(code: string) {
      const { data: response } = await cxmService.googleLogin(code);

      const { data } = response;

      this.access_token = data.access_token;
      this.login_provider = LoginProvider.Google;

      await this.invalidate();
      await this.getCurrentUser();
    },
    async loginWithFacebook(code: string) {
      const { data: response } = await cxmService.facebookLogin(code);

      const { data } = response;

      this.access_token = data.access_token;
      this.login_provider = LoginProvider.Facebook;

      await this.invalidate();
      await this.getCurrentUser();
    },
    async loginWithMeli(code: string) {
      const { data: response } = await cxmService.meliLogin(code);

      const { data } = response;

      this.access_token = data.access_token;
      this.login_provider = LoginProvider.Meli;

      await this.invalidate();
      await this.getCurrentUser();
    },
    async getCurrentUser() {
      const { data } = await queryClient.ensureQueryData({
        queryKey: ['current-user'],
        meta: { persist: true },
        queryFn: async () => {
          const request = await cxmService.getCurrentUser();
          queryClient.setQueryData(['current-user'], request);
          return request;
        },
      });

      this.user = data.data;

      setLocaleByLanguageCode(this.user.language.code);

      await this.init();
    },
    async setCurrentUserPassword(data: { password: string; password_confirmation: string }) {
      if (!this.user?.id) throw new Error('User is not set.');
      await cxmService.setPassword(this.user?.id, data);
    },
    async updateCurrentUser(data: Partial<UpdateUserDTO>) {
      if (!this.user?.id) throw new Error('User is not set.');
      await cxmService.updateUser(this.user?.id, data);
      await queryClient.removeQueries({
        queryKey: ['current-user'],
      });
      await this.getCurrentUser();
    },
    async logout() {
      await cxmService.logout();

      localStorage.clear();

      this.access_token = undefined;
      this.login_provider = undefined;
      this.user = undefined;

      await this.invalidate();

      if (isProduction()) {
        const intercom = getIntercom();
        if (intercom) {
          intercom.shutdown();
        }
        VueGtag.set({ user_id: '' });
      }
    },
    async createOrder(data: CreateAccountOrderDTO) {
      if (!this.user?.id) throw new Error('User is not set.');
      await cxmService.createOrder(data);
      await this.invalidate();
      await this.getCurrentUser();

      await this.init();
    },
    async createAccount(data: CreateAccountDTO) {
      if (!this.user?.id) throw new Error('User is not set.');
      await cxmService.createAccount(data);
      await this.invalidate();
      await this.getCurrentUser();

      this.init();
    },
    async setCurrentAccount(account_id: number) {
      if (!this.user?.id) throw new Error('User is not set.');
      await cxmService.setCurrentAccount(this.user?.id, account_id);

      await this.invalidate();
      await this.getCurrentUser();
    },
    async updateCurrentAccount(data: Partial<UpdateAccountDTO>) {
      if (!this.currentAccount?.id) throw new Error('Account is not set.');
      await cxmService.updateAccount(this.currentAccount?.id, data);
      await queryClient.removeQueries({
        queryKey: ['current-user'],
      });
      await this.getCurrentUser();
    },
    async setCurrentOrder(order_id: number) {
      if (!this.user?.id) throw new Error('User is not set.');
      await cxmService.setCurrentOrder(this.user?.id, order_id);
      await queryClient.removeQueries({
        queryKey: ['current-user'],
      });
      await this.getCurrentUser();
    },
    subscribeToNotificationSocket() {
      if (!this.user?.id) return;
      this.notificationSocket = new NotificationSocket(this.user.id);
    },
    async invalidate() {
      const ecommerceStore = useEcommerceStore();
      await ecommerceStore.clear();
      await queryClient.removeQueries({
        queryKey: ['current-user'],
      });
    },
  },
});
