export enum LandingStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR',
}

export interface Landing {
  id: number;
  reference_id: string;
  order_id: number;
  account_id: number;
  theme_id: number;
  color_id: number;
  color: Color;
  address_id: number;
  status: LandingStatus;
  name: string;
  slug: string;
  t_data: null;
  seo_metadata?: SEOMetadata;
  recaptcha_key: null;
  antispam: number;
  geolocation: number;
  locale: string;
  logo?: Logo;
  created_by: number;
  updated_by: number;
  published_by: number;
  created_at: Date;
  updated_at: Date;
  main_publication_url?: string;
  draft_url: string;
}

export interface Color {
  id: number;
  name: string;
  color: string;
}

export interface Logo {
  id: string;
  account_id: number;
  filename: string;
  url: string;
  size: string;
  description: string;
  tags: any[];
  action: string;
  created_at: Date;
  updated_at: Date;
}

export interface SEOMetadata {
  url?: string;
  image?: string;
  title?: string;
  keywords?: string[];
  description?: string;
}
