<script setup lang="ts">
import ConfirmModal from './components/common/ConfirmModal.vue';
import PlanSubscriptionApprovalModal from './components/plan-subscription-approval/PlanSubscriptionApprovalModal.vue';
import ScreenLoader from './components/common/ScreenLoader.vue';
import SupportDrawer from './components/support/SupportDrawer.vue';
import ToastContainer from './components/toast/ToastContainer.vue';
import ProductSuggestionsDrawer from './components/product/ProductSuggestionsDrawer.vue';
import { Head } from '@unhead/vue/components';

const { isLoading } = useUi();
const { currentAppName, currentFavicon } = useApp();

const internalLoading = ref(false);

onMounted(() => {
  setTimeout(() => {
    internalLoading.value = isLoading.value;
  }, 1000);

  document.querySelector('link[rel="icon"]')?.setAttribute('href', currentFavicon.value);
});

watch(isLoading, (newVal) => {
  internalLoading.value = newVal;
});

watch(
  () => currentFavicon.value,
  (newVal) => {
    document.querySelector('link[rel="icon"]')?.setAttribute('href', newVal);
  },
);

const route = useRoute();

const routeName = computed(() => {
  if (route.meta.title) return `${route.meta.title} | ${currentAppName.value}`;
  //if the route doesn't have a title, check if it has a parent route with a title
  if (route.matched.find((r) => !!r.meta.title))
    return `${route.matched.find((r) => !!r.meta.title)?.meta.title} | ${currentAppName.value}`;
  //if the route doesn't have a title at all, use the app name
  return currentAppName.value;
});
</script>

<template>
  <Head>
    <title>{{ routeName }}</title>
  </Head>
  <div
    v-show="internalLoading"
    class="flex size-full h-screen items-center justify-center opacity-0 transition-opacity delay-500 duration-500"
    :class="{ 'opacity-100': internalLoading }"
  >
    <div class="flex flex-col items-center justify-center gap-3">
      <ScreenLoader />
    </div>
  </div>
  <RouterView v-slot="{ Component }">
    <component v-if="!internalLoading" :is="Component"></component>
  </RouterView>

  <!-- Global components -->
  <ToastContainer />
  <SupportDrawer />
  <PlanSubscriptionApprovalModal />
  <ConfirmModal />
  <ProductSuggestionsDrawer />
</template>

<style lang="scss"></style>
