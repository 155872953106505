import { AppType, useAppStore } from '@/stores/app.store';

export function useApp() {
  const appStore = useAppStore();

  return {
    currentApp: computed(() => appStore.current_app),
    currentAppName: computed(() => appStore.currentAppName),
    currentFavicon: computed(() => {
      return appStore.current_app === AppType.Flokee
        ? '/favicon/favicon-flokee.ico'
        : '/favicon/favicon-engeni.ico';
    }),
  };
}
