import {
  EcommerceSocketEvents,
  type SuggestionProgressEventPayload,
} from './ecommerce.socket.interface';
import { BaseSocket } from './base.socket';

/**
 * Handles WebSocket connections for ecommerce-related real-time communications.
 */
export class EcommerceSocket extends BaseSocket {
  private static readonly CHANNEL_PREFIX = 'private-flok.ecommerces';

  /**
   * Creates an instance of EcommerceSocket.
   * @param {number} ecommerce_id - The ID of the ecommerce to subscribe to.
   */
  constructor(ecommerce_id: number) {
    const channelName = `${EcommerceSocket.CHANNEL_PREFIX}.${ecommerce_id}`;
    super(channelName);
  }

  /**
   * Binds a callback to handle suggestion progress events.
   * @param {Function} callback - The callback to execute when suggestion progress is received.
   */
  onSuggestionProgress(callback: (data: SuggestionProgressEventPayload) => void): void {
    this.bind(EcommerceSocketEvents.SuggestionProgress, callback);
  }
}
