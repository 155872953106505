import { i18n } from '@/plugins/i18n';

const isOpen = ref(false);
interface ModalProps {
  title: string;
  description: string;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl';
  confirmButtonText?: string;
  cancelButtonText?: string;
  invertButtons?: boolean;
  icon?: string;
  type?: 'error' | 'info' | 'warning' | 'success';
  static?: boolean;
}

const { t } = i18n.global as any;

const modalProps = reactive<ModalProps>({
  title: '',
  description: '',
  size: 'md',
  confirmButtonText: t('common.confirm'),
  cancelButtonText: t('common.cancel'),
  invertButtons: false,
  type: 'warning',
  static: false,
});

let resolvePromise: (value: unknown) => void;
let rejectPromise: (reason?: any) => void;

export const useConfirmModal = () => {
  function openModal(props: Partial<ModalProps> = {}) {
    Object.assign(modalProps, props); // Merge provided props with existing ones
    isOpen.value = true;
    return new Promise((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });
  }

  function handleConfirm() {
    closeModal();
    if (resolvePromise) resolvePromise('confirmed');
  }

  function handleCancel() {
    closeModal();
    if (rejectPromise) rejectPromise('canceled');
  }

  function closeModal() {
    isOpen.value = false;
    setTimeout(() => {
      resetModal();
    }, 500);
  }

  function resetModal() {
    Object.assign(modalProps, {
      title: '',
      description: '',
      size: 'md',
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
      type: 'warning',
      static: false,
    });
  }

  return {
    isOpen,
    modalProps,
    openModal,
    closeModal,
    handleCancel,
    handleConfirm,
  };
};
