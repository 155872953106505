import { pusher } from '@/plugins/pusher';
import type { Channel } from 'pusher-js';

/**
 * Base class for WebSocket connections providing common functionality.
 */
export abstract class BaseSocket {
  protected channel: Channel | null = null;
  protected events: string[] = [];
  protected readonly channelName: string;

  /**
   * Creates an instance of BaseSocket.
   * @param {string} channelName - The full channel name to subscribe to.
   */
  constructor(channelName: string) {
    this.channelName = channelName;
    this.channel = pusher.subscribe(this.channelName);

    console.log(`Subscribed to ${this.channelName}`);
    this.init();
  }

  /**
   * Initialize the socket connection and setup event listeners
   */
  protected async init(): Promise<void> {
    await this.waitForConnection();
    this.setupEventListeners();
  }

  /**
   * Returns a promise that resolves when the socket connection is established
   */
  protected waitForConnection(): Promise<void> {
    return new Promise((resolve) => {
      this.channel?.bind('pusher:subscription_succeeded', resolve);
    });
  }

  /**
   * Setup event listeners - default empty implementation so it can be optionally implemented in child classes
   */
  protected setupEventListeners(): void {}

  /**
   * Executes a callback when the socket is subscribed.
   * @param {Function} callback - The callback to execute when the socket is subscribed.
   */
  protected mounted(callback: (data: any) => void): void {
    this.channel?.bind('pusher:subscription_succeeded', callback);
  }

  /**
   * Bind all events to the socket.
   * @param {Function} callback - The callback to execute when any event is triggered.
   */
  protected bindAll(callback: (data: any) => void): void {
    this.channel?.bind_global(callback);
  }

  /**
   * Binds an event to the socket.
   * @param {string} event - The event to bind.
   * @param {Function} callback - The callback to execute when the event is triggered.
   */
  protected bind(event: string, callback: (data: any) => void): void {
    if (this.events.includes(event)) this.unbind(event);
    this.channel?.bind(event, callback);
    this.events.push(event);
  }

  /**
   * Unbinds all events from the socket.
   */
  public unbindAll(): void {
    if (!this.channel) return;
    this.events.forEach((event) => this.unbind(event));
    this.events = [];
  }

  /**
   * Unbinds a specific event from the socket.
   * @param {string} event - The event to unbind.
   */
  public unbind(event: string): void {
    if (!this.channel) return;
    this.channel.unbind(event);
    this.events = this.events.filter((e) => e !== event);
  }

  /**
   * Closes the WebSocket connection and cleans up resources.
   */
  public close(): void {
    if (!this.channel) return;

    this.unbindAll();
    pusher.unsubscribe(this.channelName);
    this.channel = null;
  }
}
